import React from 'react';
import { Box, Chip } from '@mui/material'

function TagList({ tags }) {
  return (
    <Box sx={{
      display: 'inline-flex',
      flexFlow: 'row wrap',
      gap: '2px 4px',
    }}>
      {tags.map((tag, j) => (
        <Chip key={j} size='small' variant='outlined' label={tag} />
      ))}
    </Box>
  )
}

export default TagList;