import React from 'react';
import { Box, Chip, Typography, Button } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';

import TagList from './TagList';

function Project({ project, sx = {} }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const demoClick = () => {
    navigate(`/demo/${project.name}`)
  }

  return (
    <Box sx={{
      ...sx,
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: '1rem',
      // alignItems: 'stretch',
      [theme.breakpoints.down('md')]: {
        flexFlow: 'column nowrap'
      },
    }}>
      <Box sx={{
        flex: '1 1 auto'
      }}>
        <Box sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'baseline'
        }}>
          <Typography variant='h2' sx={{
            flexGrow: 1
          }}>
            {project.name}
          </Typography>
          <Chip
            label='repository'
            size='small'
            icon={<GitHubIcon />}
            component='a'
            color='secondary'
            clickable
            href={project.repo}
            rel='noreferrer'
            target='_blank'
            sx={{
              fontWeight: 'bold',
            }}
          />
        </Box>
        <Box sx={{maxWidth: '70ch'}}>
          {project.descriptions.map((desc, j) => (
            <Typography key={j} sx={{ mt: 1 }}>
              {desc}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box sx={{
        flex: '0 1 33%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
        gap: '1rem',
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
        },
      }}>
        <Typography variant='h3'>Skills</Typography>
        <TagList tags={project.tags} />
        <Box sx={{ flex: '1 0 auto' }} />
        {project.demoable && (
          <Button
            onClick={demoClick}
            variant='contained'
            color='secondary'
            disabled={project.disabled}
            sx={{
              p: '0.3rem 3rem',
              [theme.breakpoints.down('md')]: {
                order: -1
              },
            }}
          >
            {project.disabled ? 'Available soon' : 'Demo'}
          </Button>
        )
        }
      </Box>
    </Box>
  )
}

export default Project;