import React from 'react';
import me from './me.jpg';
import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const useStyles = makeStyles(() => ({
  container: {
    padding: '100px 0'
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: 200,
    height: 200,
    borderRadius: '50%',
    marginLeft: 48
  },
}));

function Me() {
  const { box, image, container } = useStyles();

  return <Container fixed className={container}>
    <Box className={box}>
      <Box sx={{
        maxWidth: 350,
        fontSize: 18,
      }}>
        <Typography variant='fancy' component='div' sx={{
          fontWeight: '700',
          fontSize: 36,
        }}>Thomas L'Anglais</Typography>
        <Typography variant='fancy' component='div'>Principal Developer @ Treasury Board of Canada Secretariat</Typography>
        <Typography variant='fancy' component='div'>uOttawa Alumni 2020</Typography>
      </Box>
      <img className={image} src={me} alt="Me smiling" />
    </Box>
  </Container>
}

export default Me;