import React from 'react';
import { Tabs, Tab } from '@mui/material';

import { a11yProps } from './a11yProps';

function ProjectTabs({ projects, activeProject, onChange, useVerticalTabs, sx = {} }) {
  return (
    <Tabs
      sx={sx}
      value={activeProject}
      textColor='inherit'
      indicatorColor='secondary'
      onChange={onChange}
      orientation={useVerticalTabs ? 'vertical' : 'horizontal'}
    >
      {projects.map((project, i) => (
        <Tab
          key={i}
          value={i}
          label={project.name}
          {...a11yProps(i)}
        />
      ))}
    </Tabs>
  )
}

export default ProjectTabs;