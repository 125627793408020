import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";

import Home from "./components/Home";
import TLangDemo from "./components/TLangDemo";

function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="demo">
        <Route path="t-lang" element={<TLangDemo />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>404</p>
          </main>
        }
      />
    </ReactRoutes>
  );
}

export default Routes;
