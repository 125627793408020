import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import init, { start, TerminalApi } from "tlang-wasm";
import CliInput from "./CliInput";

/**
 * The cached wasm module is loaded as followed
 * 1. TlangDemo on mount effect triggers
 * 2. call init() if it's undefined.
 *
 * Then when the input is entered, the component waits until
 * the promise resovles */
let wasm;

function CommandLineInterface() {
  const theme = useTheme();

  const codeInputRef = useRef(null);
  const [terminal, setTerminal] = useState(null);
  const [keyboardInterface, setKeyboardInterface] = useState(null);
  const [outputInterface, setOutputInterface] = useState(null);

  const [history, setHistory] = useState([]);
  const [active, setActive] = useState(false);

  const onClickCLI = () => setActive(true);

  // Lifecycle
  useEffect(() => {
    const configureTerminal = async () => {
      console.log('mounting terminal api...')
      if (!wasm) {
        wasm = await init();
        start();
      }
      const _terminal = new TerminalApi(codeInputRef.current);
      const _outputInterface = _terminal.output_interface();
      const _keyboardInterface = _terminal.browser_keyboard_interface();

      setTerminal(_terminal);
      setKeyboardInterface(_keyboardInterface);
      setOutputInterface(_outputInterface);

      console.log('mounting terminal api complete!!!');
    }
    configureTerminal();
  }, []);

  useEffect(() => {
    if (!terminal) return;
    // we dont need to free the terminal because it's pointer
    // gets destroyed.
    terminal.run_repl_loop();
  }, [terminal]);
  
  useEffect(() => () => {
      if (!keyboardInterface) return;
      console.log("unmounting keyboard api...");
      keyboardInterface.free();
      console.log('unmounting keyboard api complete!!!');
    }, [keyboardInterface]);
  
  useEffect(() => {
    if (!outputInterface) return;

    const interval = setInterval(() => {
      const output = outputInterface.poll();
      if (output) {
        const {code, result} = output;
        setHistory(prev => [...prev, `> ${code}`, result]);
      }
    }, 50);

    return () => {
      if (!outputInterface) return;
      console.log("unmounting output api...");
      outputInterface.free();
      clearInterval(interval);
      console.log('unmounting output api complete!!!');
    }
  }, [outputInterface]);

  const activeCLI = theme.transitions.create("border-color", {
    easing: theme.transitions.easing.sharp,
  });
  return (
    <Box
      sx={{
        // box stylings
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        bgcolor: "black",
        padding: 2,
        // active cli border settings
        transition: activeCLI,
        borderColor: active ? theme.palette.secondary.main : "black",
        borderStyle: "solid",
        borderRadius: "inherit",
        borderWidth: 5,
        // text stylings
        whiteSpace: "pre",
        fontFamily: "monospace",
        fontWeight: "bold",
        color: "white",
        ".simplebar-scrollbar:before": {
          background: theme.palette.common.white,
        },
      }}
      onClick={onClickCLI}
    >
      {/* Custom scrollbar */}
      <SimpleBar style={{ height: "inherit" }}>
        {/* TODO: if I want the output text to wrap, I'll need to change what object I return from tlang */}
        {history.map((out, i) => (
          <p key={i}>{out}</p>
        ))}
        <CliInput active={active} setActive={setActive} keyboardInterface={keyboardInterface} ref={codeInputRef} />
      </SimpleBar>
    </Box>
  );
}

export default CommandLineInterface;
