import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Box, useTheme } from '@mui/system';

import ProjectTabs from './ProjectTabs';
import Project from './Project';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '50vh',
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '0 2rem',
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '0 0 80%',
    gap: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  tabPanel: {
    flex: '0 1 auto',
  },
}));

function ProjectList({ projects }) {
  const { container, tabs, tabPanel } = useStyles();
  const theme = useTheme();
  const useVerticalTabs = !useMediaQuery(theme.breakpoints.down('md'));

  const [activeProject, setActiveProject] = useState(0);

  const onChange = (_, newValue) => {
    setActiveProject(newValue)
  }

  return (
    <Box className={container}>
      <Box className={tabs}>
        <Box sx={{ flex: '0 1 20%'}}>
          <ProjectTabs
            projects={projects}
            activeProject={activeProject}
            onChange={onChange}
            useVerticalTabs={useVerticalTabs} />
        </Box>
        {projects.map((project, i) => (
          <TabPanel className={tabPanel} key={i} index={i} value={activeProject}>
            <Project project={project} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default ProjectList;