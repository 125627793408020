
const PROJECTS = [
  {
    name: 't-lang',
    descriptions: [
      `This project started from a cancelled work research project on
      how we could create a custom query language.`,
      `I used this as an opportunity to learn how language like python, and
      javascript work; as well as, learn a non-OOP language to broaden my skill set.`,
      `t-lang is an interpreted language written in rust.`
    ],
    repo: 'https://github.com/Thomas-Langlais/t-lang',
    tags: ['rust', 'wasm', 'interpreters', 'programming language'],
    demoable: true,
  }
];

export async function getProjects() {
  return PROJECTS
}

export async function getProject(name) {
  return PROJECTS.find(p => p.name === name)
}