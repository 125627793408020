import {
  Box,
  Chip,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GitHubIcon from "@mui/icons-material/GitHub";
import React, { useEffect, useState } from "react";

import CommandLineInterface from "./CommandLineInterface";
import { getProject } from "../../projects";

function TLangDemo() {
  const theme = useTheme();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      const project = await getProject("t-lang");
      setProject(project);
    };
    fetchProject();
  }, []);

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        minHeight: "100vh",
        p: 8,
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          gap: 3,
          gridTemplateRows: "auto auto 1fr",
          gridTemplateColumns: "80ch minmax(350px, 500px)",
          gridTemplateAreas: ['"header ."', '"cli notes"', '"cli notes"'].join(
            "\n"
          ),
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "auto auto",
            gridTemplateAreas: [
              '"header header"',
              '"cli cli"',
              '"notes notes"',
            ].join("\n"),
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            gridArea: "header",
            justifySelf: "center",
          }}
        >
          T-lang console
        </Typography>
        <Paper
          elevation={12}
          sx={{
            gridArea: "cli",
            maxHeight: "70vh",
            borderRadius: 7,
          }}
        >
          <CommandLineInterface />
        </Paper>
        <Paper
          elevation={4}
          sx={{
            gridArea: "notes",
            p: 4,
            borderRadius: 7,
          }}
        >
          {project ? (
            <Chip
              label="repository"
              color="info"
              icon={<GitHubIcon />}
              component="a"
              clickable
              href={project.repo}
              rel="noreferrer"
              target="_blank"
            />
          ) : (
            <Skeleton
              variant="rectangular"
              style={{
                borderRadius: 16,
              }}
            >
              <Chip label="repository" />
            </Skeleton>
          )}
          <Typography variant="h2">Notes</Typography>
          <Box
            sx={{
              "& .MuiTypography-body1": {
                mt: 2,
                mb: 2,
              },
              pre: {
                p: 2,
                borderRadius: 2,
              },
              code: {
                p: "4px 8px",
                borderRadius: 2,
              },
              "pre > code": {
                p: "unset",
                borderRadius: "unset",
              },
              "code, pre": {
                bgcolor: theme.palette.grey[200],
                color: theme.palette.black,
              },
            }}
          >
            <Box>
              <Typography>
                T-lang can interpret statement(s) using the following rules
              </Typography>
              <ul>
                <li>variables (e.g. let a = 5)</li>
                <li>integers and floats and booleans</li>
                <li>operations +, -, *, /</li>
                <li>
                  wrap expressions with <code>(</code> and <code>)</code> to
                  change the order of operations
                </li>
              </ul>
              <Typography>
                To start a new line without executing, press{" "}
                <code>Shift+Enter</code>
              </Typography>
              <Typography>
                <strong>Warning!</strong> executing an infinite loop will freeze
                the page. Be sure your loops don't run forever!
              </Typography>
            </Box>
            <Typography variant="h3">Syntax</Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <strong>If statements</strong>
              </AccordionSummary>
              <AccordionDetails>
                <pre>
                  <code>
                    {
                      "if a == 50 |-\n\tlet b = false;\n-| else |-\n\tlet c = 50;\n-|"
                    }
                  </code>
                </pre>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <strong>Loops</strong>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To break out of a for/while loop, use the <code>brk;</code>{" "}
                  statement
                </Typography>
                <Typography>
                  To continue running the for/while loop, use the{" "}
                  <code>con;</code> statement
                </Typography>
                <pre>
                  <code>
                    {
                      "for (let i = 0; i < 5; let i = i + 1) |-\n\tlet b = false;\n-|"
                    }
                  </code>
                </pre>
                <pre>
                  <code>
                    {
                      "let i = 0;\nlet a = 0;\nwhile i < 5 |-\n\tlet a = a + i;\n\tlet i = i + 1;\n-|"
                    }
                  </code>
                </pre>
              </AccordionDetails>
            </Accordion>
            <Typography variant="h3">Limitations</Typography>
            <Typography>
              As of tlang-wasm version <b>v0.2.1</b>, execution results are
              returned as strings. All errors are pre-formatted for monospace
              fonts and allowed leading whitespaces. This limits the css
              stylings because we cannot wrap horizontal overflow with{" "}
              <i>white-space: 'pre'</i> set on the execution result elements.
            </Typography>
            <Typography>
              There are plans to get the WASM interface to send font agnostic
              execution results back to the UI, however I want to add more core
              functionality to the language before overhauling the data returned
              from the WASM interface.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default TLangDemo;
