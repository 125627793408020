import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import Footer from './Footer';
import Me from './Me';
import ProjectList from './ProjectList';
import { getProjects } from '../projects';

function Home() {
  const theme = useTheme();

  const [projects, setProjects] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      setFetching(true);
      try {
        const data = await getProjects();
        setProjects(data);
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    }

    fetchProjects();
  }, [])

  return (
    <>
      <header>
        <Me />
      </header>
      <main>
        <Box sx={{ backgroundImage: theme.palette.secondary.bg, padding: '1rem 0.5rem', textAlign: 'center'}}>
          <Typography variant='h1' color='secondary.contrastText'>Projects</Typography>
        </Box>
        {!fetching && <ProjectList projects={projects} />}
      </main>
      <Footer />
    </>
  )
}

export default Home;