import React from 'react';
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

import './animations.css'
import { ReactComponent as ReactIcon } from './logo.svg'

const useStyles = makeStyles(_ => ({
  container: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  reactLogo: {
    height: 75,
    width: 75,
  }
}))

function BuiltWith() {
  const { container, reactLogo } = useStyles();

  return (
    <Box className={container} component="span">
      <Typography component="span">built with</Typography>
      <ReactIcon id="built-with-react" className={reactLogo} title='react logo' />
    </Box>
  )
}

export default BuiltWith;