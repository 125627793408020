import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    position: 'absolute',
    right: 12,
    flexDirection: 'row',
    gap: '0.5rem',
  },
  item: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

function Socials() {
  const { list, item } = useStyles();

  return (
    <Box className={list} component="span">
      <a className={item} rel='noreferrer' target='_blank' href="https://www.facebook.com/thomas.langlais1/">
        <FacebookIcon fontSize='small' titleAccess='my facebook profile' />
      </a>
      <a className={item} rel='noreferrer' target='_blank' href="https://github.com/Thomas-Langlais">
        <GitHubIcon fontSize='small' titleAccess='my github profile' />
      </a>
      <a className={item} rel='noreferrer' target='_blank' href="https://www.linkedin.com/in/thomas-l-anglais-939661109/">
        <LinkedInIcon fontSize='small' titleAccess='my linkedin profile' />
      </a>
    </Box>
  )
}

export default Socials;