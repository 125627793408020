import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';

import blinkModule from '../../animations/blink.module.css';

const CliActiveLine = styled('span')({
  position: 'relative',
  display: 'inline-block',
  '::before': {
    content: '"> "',
    position: 'relative',
    left: 0,
    width: '1ch',
    height: '100%',
  },
  '.terminal__text': {
    display: 'inline-block',
  },
  '.terminal__cursor': {
    display: 'inline-block',
    width: '0.25ch',
    height: '1rem',
    verticalAlign: 'text-bottom',
    backgroundColor: 'transparent',
  }
});

/**
 *
 * @param {object} props
 * @param {boolean} props.active
 * @param {Function} props.setActive
 * @param {import('tlang-wasm').BrowserKeyboardInterface} props.keyboardInterface
 * @param {React.Ref} ref
 * @return {React.ReactElement} 
 */
function CliInput({ active, setActive, keyboardInterface }, ref) {
  const inputEl = useRef(null);  
  const [starting, setStarting] = useState(true);
  const [input, setInput] = useState('Click or tab in to begin!');

  const onKeyDown = async (e) => {
    if (keyboardInterface)
      keyboardInterface.send_keyboard_event(e);
    e.preventDefault();
  };
  const onFocusInput = _ => {
    if (starting) {
      setStarting(false);
      setInput('');
    }
    setActive(true);
  }
  const onBlurInput = () => setActive(false);

  // Lifecycle
  useEffect(() => {
    if (active && document.activeElement !== inputEl.current)
      inputEl.current.focus();
  }, [active]);

  return (
    <>
      <p>
        <CliActiveLine ref={ref} sx={{
          '.terminal__cursor': active ? {
            backgroundColor: 'white',
            animation: `${blinkModule.blink} 1.5s step-end infinite`
          } : null
        }}>{input}</CliActiveLine>
      </p>
      <input
        ref={inputEl}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onKeyDown={onKeyDown}
        type="text"
        style={{ opacity: 0, width: 0, height: 0 }}
      />
    </>
  )
}

export default React.forwardRef(CliInput);