import React from 'react';
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import BuiltWith from '../BuiltWith';
import Socials from '../Socials';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    padding: '20px 12px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    fill: theme.palette.grey[500],
  }
}));

function Footer() {
  const { container } = useStyles();

  return (
    <Box
      component='footer'
      className={container}
      bgcolor='grey.200'
    >
      <BuiltWith />
      <Socials />
    </Box>
  )
}

export default Footer;