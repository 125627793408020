import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: 14,
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    button: {
      textTransform: "none",
      letterSpacing: 0,
      fontWeight: "bold"
    },
    fancy: {
      fontFamily: "'Lato', sans-serif;",
      fontStyle: 'italic',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          margin: 'revert'
        },
        h2: {
          margin: 'revert'
        },
        h3: {
          margin: 'revert'
        },
        h4: {
          margin: 'revert'
        },
        h5: {
          margin: 'revert'
        },
        h6: {
          margin: 'revert'
        },
      }
    }
  },
  palette: {
    primary: { main: "#3A8DFF" },
    secondary: {
      main: 'rgba(255,99,178,1)',
      contrastText: '#fff',
      bg: 'linear-gradient(120deg, rgba(255,99,178,1) 10%, rgba(205,70,142,1) 97%)'
    }
  }
});